body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
    font-family: 'Avenir Black Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Black Oblique'), url(/static/media/avenir_black_oblique.292dd00e.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Black';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Black'), url(/static/media/avenir_black.968e7849.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Book Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Book Oblique'), url(/static/media/avenir_book_oblique.aa0e2e73.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Book';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Book'), url(/static/media/avenir_Book.995b34ab.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Heavy Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Heavy Oblique'), url(/static/media/avenir_heavy_oblique.bd62ce88.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Heavy'), url(/static/media/avenir_heavy.f080b689.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Light Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Light Oblique'), url(/static/media/avenir_light_oblique.0e057a2e.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Light';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Light'), url(/static/media/avenir_light.33f6570a.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Medium Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Medium Oblique'), url(/static/media/Avenir_medium_oblique.10db54e9.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Medium'), url(/static/media/avenir_medium.7d635ea9.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Oblique'), url(/static/media/avenir_oblique.10d2e9dc.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Roman';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Roman'), url(/static/media/avenir_roman.c6d37b26.ttf) format('truetype');
}

.loginPage {
    width: 100vw;
    min-height: 100vh;
    background-size: cover
}

.loginPage-container {
    text-align: center;
    width: 50%;
    margin-left: 25%;
    font-family: 'Avenir Heavy'
}

.loginPage-header img {
    width: 34%;
    margin-top: 10%;
    margin-right: 11%;
}

.loginPage-text p {
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 8%;
}

.loginPage-form {
    text-align: center;
    font-family: 'Avenir Medium'
}

.loginPage input {
    background-color: #ffffff !important;
    border-radius: 50px;
    padding: 0px 30px;
    border-color: transparent;
    color: #6f6f6f !important;
    font-size: 16px;
    font-family: 'Avenir Medium';
    line-height: 20px;
    box-shadow: none;
    height: 48px !important;
    margin-bottom: 8px;
    width: 50%;
}

.loginPage button {
    color: white !important;
    font-family: 'Avenir Medium';
    border-radius: 21px;
    font-size: 1rem;
    text-transform: none;
    text-transform: initial;
    height: 3rem;
    border: none;
    width: 50%
}

.loginPage-header {
    margin-left: 11% !important;
}

.loginPage-box:focus {
    border: 1px solid #ff4694 !important;
    box-shadow: none !important
}

.loginPage-signIn {
    color: white;
    background-color: #ff4694 !important;
}

.loginPage-signIn:hover {
    background-color: #363287 !important;
    border-color: #363287 !important;
}

.loginPage-link {
    margin-bottom: 23px;
    color: #fff !important;
    font-size: 16px;
    line-height: 18px;
    margin-top: 22px !important;
}

.loginPage-link a {
    color: #fff
}

.loginPage-link a:hover {
    color: #3c8dbc
}

.googleIcon {
    color: #fff;
    width: 23px;
    height: 23px;
    margin-bottom: 1px;
    position: relative;
    right: 4px;
    bottom: 1px;
}

.loginPage-google {
    background-color: #00aeef !important;
}

.LoginError {
    font-size: 14px;
    color: #fff;
}

.GoogleLoginError {
    margin-top: 4%
}

/***register Choice***/

.registerChoice {
   min-height: 100vh;
    width: auto !important;
    font-family: 'Avenir Medium'
}

.registerChoice-container {
    width: 50%;
    margin-left: 25%;
    text-align: center
}

.registerChoice-header img {
    width: 30%;
    margin-top: 15%;
}

.registerChoice-text {
    font-size: 20px;
    font-family: 'Avenir Heavy';
    line-height: 30px;
    color: #fff;
    font-weight: 400;
    margin-top: 10px;
}

.registerChoice-btn {
    color: #fff !important;
    border-radius: 50px !important;
    font-size: 16px !important;
    height: 48px !important;
    border: none;
    width: 50%;
    margin-bottom: 2%
}

.registerChoice-btn-email {
    background-color: #ff4694 !important;
}

.registerChoice-btn-google {
    margin-top: 9%;
    background-color: #00aeef !important;
}

.emailIcon {
    color: #fff;
    width: 23px;
    height: 23px;
    position: relative;
    right: 13px;
}

.registerChoice-policy {
    color: #fff;
    position: fixed;
    bottom: 10px;
}

.registerChoice-policy a {
    color: #363287;
    text-decoration: underline
}

/****register page****/

.registerPage {
    min-height: 100vh;
    width: 100vw;
    background-size: cover;
    font-family: 'Avenir Medium'
}

.registerPage-header {
    text-align: center
}

.registerPage-header img {
    width: 16%;
    margin-top: 6%;
}

.registerPage-text {
    text-align: center;
    font-family: 'Avenir Heavy'
}

.registerPage-text p {
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 3%;
}

.registerStep {
    text-align: center;
}

/**step1*/

.registerstep1 {
    text-align: center
}

.registerstep1 input {
    background-color: #ffffff !important;
    border-radius: 50px;
    padding: 0px 30px;
    border-color: transparent;
    color: #6f6f6f !important;
    font-size: 16px;
    line-height: 20px;
    box-shadow: none;
    height: 48px !important;
    width: 20%;
    font-family: 'Avenir Medium';
    margin: auto
}

.registerStep1-btn {
    width: 20%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-top: 3%;
}

.registerStep1-btn button {
    background-color: #ff4694 !important;
    color: white !important;
    border-radius: 21px;
    font-size: 1rem;
    text-transform: none;
    text-transform: initial;
    height: 3rem;
    border: none;
    width: 45%
}

/**step2**/

.registerStep2 {
    text-align: center;
}

.registerStep2 input {
    background-color: #ffffff !important;
    border-radius: 50px;
    padding: 0px 30px;
    border-color: transparent;
    color: #6f6f6f !important;
    font-size: 16px;
    line-height: 20px;
    box-shadow: none;
    height: 48px !important;
    width: 20%;
    margin: auto
}

.registerStep2 select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius: 50px;
    background-color: #ffffff !important;
    border-radius: 50px;
    padding: 0px 30px;
    border-color: transparent;
    color: #6f6f6f !important;
    font-size: 16px;
    line-height: 20px;
    box-shadow: none;
    height: 48px !important;
    margin: auto;
    width: 20%;
    border:0px;
    outline:0px;
}

.registerStep2-btn {
    width: 20%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-top: 3%;
}

.registerStep2-btn button {
    background-color: #ff4694 !important;
    color: white !important;
    border-radius: 21px;
    font-size: 1rem;
    text-transform: none;
    text-transform: initial;
    height: 3rem;
    border: none;
    width: 45%;
}

/**step3*/

.registerstep3 {
    text-align: center
}

.registerstep3 button {
    background-color: #ff4694 !important;
    color: white !important;
    border-radius: 21px;
    font-size: 1rem;
    text-transform: none;
    text-transform: initial;
    width: 20%;
    height: 3rem;
    border: none;
}

.registerStep3-text {
    width: 100%;
    color: #fff;
}

.registerStep3-btn {
    width: 100%;
    margin-top: 7%;
}

.registerStep3-text-p1 {
    font-weight: 500;
}

.registerStep3-text-p3 {
    margin-bottom: 3%
}

.registerStep3-input {
    width: 20%;
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
    margin: auto
}

.registerstep3 input {
    background-color: transparent !important;
    /* border-bottom: 4px solid #ffff; */
    text-align: center;
    border-bottom-color:white;
    border-bottom-width: 3px;
    border-top: none;
    border-left: none;
    border-right: none;
    color: #fff !important;
    font-size: 30px;
    font-weight: 400;
    line-height: 20px;
    box-shadow: none;
    height: 48px !important;
    width: 16%;
    -webkit-appearance: none;
    border-radius: 0;
    font-family: 'Avenir Medium'
}

.registerError-step3 {
    font-size: 14px;
    color: #fff !important;
    margin-top: 1%;
}

.registerstep3 input:focus {
    box-shadow: none !important;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-color:white;
    border-bottom-width: 3px;
}

/**step 4*/

.registerStep4 {
    text-align: center;
}

.registerStep4 input {
    background-color: #ffffff !important;
    border-radius: 50px;
    padding: 0px 30px;
    border-color: transparent;
    color: #6f6f6f !important;
    font-size: 16px;
    line-height: 20px;
    box-shadow: none;
    height: 48px !important;
    width: 20%;
    font-family: 'Avenir Medium';
    margin: auto;
}

.registerStep4 button {
    background-color: #ff4694 !important;
    color: white !important;
    border-radius: 21px;
    font-size: 1rem;
    text-transform: none;
    text-transform: initial;
    width: 20%;
    height: 3rem;
    border: none;
}

.registerStep4-btn {
    margin-top: 5%
}

.selectIcon {
    position: absolute;
    top: 30%;
    left: 44%;
}
.registerStep5{
    margin-top:-2%
}
.registerstep1 input:focus, .registerStep2 input:focus, .registerStep4 input:focus, .registerStep5 input:focus, .registerPage select:focus {
    border: 1px solid #ff4694 !important;
    box-shadow: none !important
}

.registerStep button {
    background-color: #ff4694 !important;
    color: white !important;
    border-radius: 21px;
    font-size: 1rem;
    text-transform: none;
    text-transform: initial;
    width: 50%;
    height: 3rem;
    border: none;
}

.registerStep button:hover {
    background-color: #363287 !important;
    border-color: #363287 !important;
}

.registerIcon {
    margin-top: 29%
}

/**step5**/

.registerStep5 {
    text-align: center;
}

.registerStep5 input {
    background-color: #ffffff !important;
    border-radius: 50px;
    padding: 0px 30px;
    border-color: transparent;
    color: #6f6f6f !important;
    font-size: 16px;
    line-height: 20px;
    box-shadow: none;
    height: 48px !important;
    width: 50%;
}

.registerStep5-text {
    color: #fff;
    font-size: 20px;
}

.registerStep5-uploadButton {
    margin-top: 3%
}

#registerFileInput {
    background: #363287;
    display: table;
    color: #fff;
    border-radius: 21px;
    font-size: 1rem;
    text-transform: none;
    text-transform: initial;
    width: 20%;
    height: 3rem;
    border: none;
    padding: 10px;
    margin: auto;
}

.registerStep5-cam {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: -2%;
}

.registerStep5-cam button {
    background-color: #ff4694 !important;
    color: white !important;
    border-radius: 21px;
    font-size: 1rem;
    text-transform: none;
    text-transform: initial;
    height: 3rem;
    border: none;
    margin: auto;
    width: 20%;
}

.registerCamera {
    margin-top: 35%;
}

.registerStep5-btn {
    display: flex;
    justify-content: space-between;
    width: 20%;
    margin: auto;
    padding-top: 1%;
}

.registerStep5-btn button {
    background-color: #ff4694 !important;
    color: white !important;
    border-radius: 21px;
    font-size: 1rem;
    text-transform: none;
    text-transform: initial;
    height: 3rem;
    border: none;
    height: 43px;
    width: 45%
}

.registerPictureZone {
    height: 200px;
    width: 200px;
    border: 1px solid #fff;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    margin: auto;
}

input[type="file"] {
    display: none;
}

.registerError {
    font-size: 14px;
    color: #fff !important;
}

.registerSkip {
    font-weight: 400;
    font-size: 20px;
    top: 25%;
    left: 85%;
    position: absolute;
}

.registerSkip a {
    color: #fff !important;
}

#modalCloseButton {
    border-radius: 0% !important
}

#modalCloseButton {
    border-radius: 0% !important
}

/*Navigation bar Menu*/

.nav-container {
    background-color: rgb(7, 69, 134);
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.nav-container .user-details {
    display: flex;
    align-items: center;
}

.user-details div {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 50px;
    width: 50px;
    border-radius: 100%;
}

.menu span:first-child {
    display: flex;
    align-items: center;
}

.back-arrow-container {
    padding: 0% 3% 3%
}

.back-arrow-style {
    color: #fd4b95;
    font-size: 24px;
}

.menu .active span {
    color: #1daff1;
}

/*Sidebar Menu*/

#sidebar {
   min-height: 100vh;
    background-color: #36343e;
    width: 20%;
    position: fixed;
}

#right-container {
    width: 80%;
    margin-left: 20%;
    position: relative;
}

#sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 20px;
    color: #ffffff;
    display: block;
}

#sidebar .menu {
    padding-top: 100px;
}

.menuSpan {
    width: 9%;
    display: flex;
    justify-content: center;
}

.modal-visible {
    display: block;
    padding-right: 15px;
    background: rgba(108, 108, 108, 0.6);
}

.modal-hidden {
    display: none;
}

.flavour-form {
    padding: 1% 2%;
    background-color: #ffffff;
    margin: 0 3%;
}

.flavorRadios {
    display: flex
}
.flavorRadios input {
    margin-left:5%
}

.sidebar-logo {
    text-align: center;
    padding-top: 30px;
}

.pink-button {
    background-color: #fd4b95;
    padding: 8px 20px;
    color: #ffffff;
    width: 48%;
    font-family: 'Avenir Heavy';
    border: none !important;
}

.cash-label {
    border: 1px solid #fd4b95;
    padding: 10px 20px;
    font-family: 'Avenir Medium';
    width: 100%;
    text-align: center;
}

.cash-label-selected {
    border: 1px solid #fd4b95;
    padding: 10px 20px;
    background-color: #fd4b95;
    color: #ffffff;
    width: 100%;
    text-align: center;
    font-family: 'Avenir Heavy'
}

.blue-button {
    background-color: #064485;
    padding: 8px 20px;
    color: #ffffff;
    width: 48%;
    font-family: 'Avenir Heavy';
    border: none !important
}

.light-blue-button {
    background-color: #1db0f2;
    padding: 8px 20px;
    color: #ffffff;
    width: 28%;
    font-family: 'Avenir Heavy';
    border: none !important
}

.light-blue-text {
    color: #1eb0f2;
}

.delete-order-link:link, .delete-order-link:visited {
    color: #b3b3b3;
    font-weight: bold;
}

.delete-order-link:hover, .delete-order-link:active {
    font-weight: bold;
}

.grey-button {
    background-color: #b3b3b3;
    padding: 8px 20px;
    color: #ffffff;
    width: 48%;
    font-family: 'Avenir Heavy';
    border: none !important;
}

.rounded-profile-image {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    background-size: cover;
    background-position: center;
}

.rounded-profile-image span {
    font-family: 'Avenir Medium'
}

.rounded-profile-image-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: rgba(108, 108, 108, 0.4);
    color: #ffffff;
}

.order-item-container {
    background-color: #ffffff;
    padding: 20px;
    margin-top: 30px;
}

.orderNote {
    font-family: 'Avenir Medium'
}

.cash-style {
    color: #fc4a94;
    font-family: 'Avenir Heavy'
}

.cash-title {
    color: #4c4c4c;
    font-weight: bold;
}

.order-text-blue {
    color: #074585;
    font-family: 'Avenir Heavy';
    margin-top: 1%
}

.order-container {
    font-family: 'Avenir Medium'
}

.flavour-title {
    color: #1daff1;
    font-family: 'Avenir Heavy'
}

.flavour-form .form-check {
    margin-right: 5rem;
}

.content-spaced-between {
    display: flex;
    justify-content: space-between;
    font-family: 'Avenir Medium'
}

.description-container {
    border-bottom: 1px solid #f2f2f2;
    margin-top: 20px;
    color: #1eaff1;
    font-family: 'Avenir Heavy'
}

.from-container {
    padding: 5%;
    background-color: #ffffff;
    position: relative;
    top: 8%;
    margin: 0 3%;
}

.MessageModal-btn button {
    background-color: #074585 !important;
    border-radius: 0% !important
}

.MessageModalClose-btn button {
    border-radius: 0% !important;
    background-color: #fff !important;
    color: #000 !important;
    width: 9%;
    font-size: 18px;
}

.settings-form select, .settings-form input {
    font-family: 'Avenir Medium' !important
}

.settingsContainer .react-datepicker-manager, .settingsContainer .react-datepicker-wrapper, .settingsContainer .react-datepicker__input-container {
    width: 100%;
}

.paymentForm label {
    text-align: left;
    font-family: 'Avenir Medium'
}

.list-unstyled {
    font-family: 'Avenir Medium'
}
#canvas{
    display: none !important;
}
@media screen and (max-device-width: 1280px) {
    .registerstep3 input {
        font-size: 18px;
        width: 15%;
    }
    /* .registerStep5{
        margin-top:-2%
    } */
}

@media screen and (min-device-width: 1600px) {
    /**login*/
    .loginPage-container {
        text-align: center;
        width: 40%;
        margin-left: 30%;
    }
    .loginPage-header img {
        width: 34%;
        margin-top: 21%;
        margin-right: 11%;
    }
    .loginPage-text p {
        font-size: 20px;
        line-height: 30px;
        color: #fff;
        font-weight: 400;
        margin-top: 10px;
        margin-bottom: 14%;
        margin-right: 0%;
    }
    .loginPage input {
        font-size: 16px;
        width: 50%;
    }
    .loginPage button {
        width: 50%;
    }
    /**rgister**/
    .registerChoice-btn {
        width: 40%
    }
    .registerChoice-policy {
        margin-left: 8%
    }
    .registerChoice-header img {
        width: 24%;
        margin-top: 21%;
    }
    .registerPage-header img {
        width: 12%;
        margin-top: 10%;
    }
    .selectIcon {
        position: absolute;
        top: 30%;
        left: 37%;
    }
    .registerStep5-cam button,  #registerFileInput{
        width:17%
    }
}
/* @font-face {
    font-family: 'Avenir Black Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Black Oblique'), url(../../assets/fonts/avenir_black_oblique.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Black';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Black'), url(../../assets/fonts/avenir_black.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Book Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Book Oblique'), url(../../assets/fonts/avenir_book_oblique.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Book';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Book'), url(../../assets/fonts/avenir_Book.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Heavy Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Heavy Oblique'), url(../../assets/fonts/avenir_heavy_oblique.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Heavy'), url(../../assets/fonts/avenir_heavy.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Light Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Light Oblique'), url(../../assets/fonts/avenir_light_oblique.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Light';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Light'), url(../../assets/fonts/avenir_light.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Medium Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Medium Oblique'), url(../../assets/fonts/Avenir_medium_oblique.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Medium'), url(../../assets/fonts/avenir_medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Oblique'), url(../../assets/fonts/avenir_oblique.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Roman';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Roman'), url(../../assets/fonts/avenir_roman.ttf) format('truetype');
} */

.HomeHeader {
    text-align: center;
    font-size: 18px;
    margin-bottom: 8%;
    font-family: 'Avenir Medium'
}

.HomeHeader-list-container {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

.cardRestau {
    position: relative;
    box-shadow: none;
    margin: 0 1.5% 25px 1.5%;
    width: 30%;
}

.cardRestau .cardRestauBody {
    padding-bottom: 46px;
}

.cardDate {
    width: 80%;
    margin-left: 10%;
    margin-top: 2%;
    display: flex;
    justify-content: space-between;
    height: 48px;
    color: rgb(0, 174, 244);
    font-family: 'Avenir Medium';
    padding-top: 3%
}

.card-text {
    color: #333333;
    font-family: 'Avenir Medium';
}

.HomeHeader span {
    position: relative;
    top: 44px;
}

.MerchantPage {
    background-color: rgb(242, 242, 242);
    margin-left: 4%;
}

.MerchantPage-btn-back {
    margin-top: 3%;
    margin-bottom: -2%;
    color: #fd4b95;
    font-size: 24px;
}

.photoMerchant {
    height: 200px;
    min-height: 200px;
    max-height: 200px;
    position: relative;
}
.logoMerchant-container{
    position: absolute;
    left:27.5%;
    height: 100px;
    top:100px;
    width: 45%;
   
    /* margin-left: 25%; */
}
.logoMerchant {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.remember {
    height: 73px;
    width: 73px;
    position: absolute;
    top: 12%;
    left: 1%;
}

.form-check-label {
    font-family: 'Avenir Medium'
}

.cardRestau-btn {
    background-color: #fd4b95;
    color: #fff !important;
    position: absolute;
    bottom: 0px;
    left: 0;
    margin-top: 2%;
    font-family: 'Avenir Heavy';
    box-shadow: none !important
}

.cardRestauLink-container a {
    color: #fff !important
}

.cardRestauLink-container a:hover {
    color: #fff !important
}

/**Merchant Header**/

.card-title {
    color: #004387;
    font-family: 'Avenir Heavy'
}

.cardRestau-radio {
    margin-top: 5%;
    color: #004387;
    padding-left: 0 !important
}

.merchantParagraph {
    background-color: #ffffff;
    color: #333333;
    height: 236px;
    padding-bottom: 4%;
    font-family: 'Avenir Medium'
}

.merchantHeader {
    top: 40px;
}

.MerchantPage {
    padding-left: 70px !important;
}

.merchantName {
    margin-top: 4%;
    color: #004387;
    font-weight: 500;
    margin-bottom: 3%;
    font-family: 'Avenir Heavy'
}

.merchantDescription {
    max-height: 64%;
    overflow-y: auto;
    text-overflow: ellipsis;
}

.DeliveryTime {
    margin-top: 2% !important;
}

.merchantMeal {
    color: #fd4b95
}

.userCart {
    background-color: #b3b3b3;
    left: 3% !important;
    padding-top: 3%;
    text-align: center;
    font-family: 'Avenir Heavy'
}

.userCart a, .userCartPink a {
    color: #ffffff
}

.userCartPink {
    padding-top: 3%;
    text-align: center;
    background-color: #fd4b95;
    left: 3% !important;
    font-weight: 400;
}

.merchantPhoto {
    opacity: 0.7;
    background-position: center center
}
.merchantLogo-container{
    height: 100px;
    width: 15%;
    position: absolute;
    top: 25%;
    right:80%
}
.merchantLogo{
    object-fit: contain;
    height: 100%;
    width: 100%;
    
}
/*** merchant body***/

.merchantBody {
    background-color: #ffffff;
    margin-top: 8%;
    padding-bottom: 2%;
    width: 94.8%;
    margin-left: 0% !important;
}

.merchantBody-container {
    width: 96%;
    padding-top: 1%;
    margin-left: 2%;
}

.merchantBody-title {
    color: #004387;
    margin-top: 2%;
    font-size: 20px;
    margin-bottom: 1%;
    font-family: 'Avenir Heavy'
}

.fa-angle-down:before {
    content: "\F107";
    box-sizing: border-box;
    position: relative;
    top: 39%;
}

.fa-angle-down.rotate-icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 150ms ease-in 0s;
    transition: all 150ms ease-in 0s;
}

.showed {
    color: #00aef4 !important;
}

.merchantBody-collapse-btn {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
    cursor: pointer;
    font-family: 'Avenir Heavy'
}

.merchantBody-collapse-btn button {
    background-color: #ffffff;
    border: none;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: none;
}

.custom-radio .custom-control-input:checked~.custom-control-label {
    color: #26b4f5
}

.custom-checkbox .custom-control-input:checked~.custom-control-label {
    color: #26b4f5;
}

.custom-control-label::after {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    border-radius: 100%;
    background-color: #007bff;
}

.custom-control-input~.custom-control-label::before {
    border-radius: 100%;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    color: #007bff;
    border-color: #007bff;
    border-radius: 100% 100% 100%;
    background-color: #007bff;
    background-image: none;
}

.merchantBody-collapse {
    padding: 0 1% 0 2%;
    -webkit-transition: none;
    transition: none
}

.productItem {
    margin-right: 2%;
    font-family: 'Avenir Medium'
}

.productItem-title {
    font-size: 15px;
    font-family: 'Avenir Medium'
}

.productItem-price {
    font-size: 15px;
    font-family: 'Avenir Medium';
    min-width: 16%;
    margin-left: 0%;
    text-align: right;
}


.colProduct {
    padding: 0px !important;
    margin-left: -0.6%;
    margin-right: 0.5%;
    font-weight: 400;
}

.MerchantMenu-btn {
    width: 100%;
    color: #fff;
    background-color: #fd4b95;
    border: none;
    height: 45px;
    margin-top: 3%;
    font-family: 'Avenir Heavy'
}

.iconSubcategoryProduct {
    position: relative;
    left: -0.5%;
}

.subCategory-collapse {
    width: 254.5%;
    margin-left: -0.7%;
}

.merchantBody-collapse-category {
    margin-bottom: 1%;
    margin-top: 1%;
    color: #5d5d5d;
    font-weight: 500;
    margin-left: 0.3%;
}

.merchantBody-collapse-Subcategory {
    margin-left: 1.3%;
    margin-bottom: 1%;
    color: #5d5d5d;
    font-weight: 500;
    font-size: 15px
}

.rowProduct {
    display: flex;
    width: 102%;
    margin-top: 1%;
    flex-wrap: wrap;
}

.rowProduct> :nth-child(2n+1) {
    margin-right: 22%;
    margin-left: -2%;
}

.rowProduct> :nth-child(2n) {
    width: 40%
}

.colProduct {
    width: 40%;
    margin-bottom: 1%;
}

.merchantBody-collapseSubcategory {
    width: 102%;
    margin-left: -1.4%
}

.merchantBody-collapseSubcategory-items {
    display: flex;
    flex-wrap: wrap;
}

.merchantBody-collapseSubcategory-items> :nth-child(2n+1) {
    margin-right: 22.9%;
}

.subcategoryProduct {
    width: 38%;
}

.productItem-subcategory-title {
    min-width: 59%;
}

.productItem-subcategory-label {
    width: 102%;
    margin-bottom: 2%;
    margin-left: -0.75%;
}

/****Merchant Product Page****/

.merchantProductPage {
    min-height: 92.2vh;
    padding-left: 70px !important;
}

.merchantProductBody {
    background-color: #ffffff;
    margin-top: 8%;
    padding-bottom: 2%;
    width: 94.8%;
    margin-left: 0% !important;
}

.merchantProductBody-container {
    width: 96%;
    padding-top: 2%;
    margin-left: 2%;
}

.merchantProductBody-title {
    color: #004387;
    font-size: 20px;
    font-family: 'Avenir Heavy'
}

.merchantProduct-quantity {
    color: #01aff5;
    font-weight: 500;
    font-size: 20px;
    position: relative;
    bottom: 15%;
}

.merchantProductBody-description {
    color: #333333;
    font-family: 'Avenir Medium'
}

.merchantProductBody-modifiers {
    color: #00aef4;
    font-size: 18px;
    font-family: 'Avenir Heavy'
}

.merchantProduct-btn {
    margin-top: 5%;
    font-family: 'Avenir Heavy'
}

.merchantProduct-btn button {
    width: 100%;
    color: #fff;
    border: none;
    background-color: #fd4b95;
    height: 45px
}

.merchantProduct-btn-cancel {
    background-color: #b3b3b3 !important
}

/*****Confirmation Page***/

.confirmationPage-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: -70px;
}

.confirmationPage-counter {
    color: #fff;
    font-size: 50px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    margin-bottom: 8%;
    font-family: 'Avenir Heavy'
}

.confirmationPage-p {
    color: #fff;
    font-size: 20px;
    width: 80%;
    text-align: center;
    margin-left: 10%;
    font-family: 'Avenir Medium'
}

.confirmationPage-btn {
    height: 60%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-family: 'Avenir Heavy';
    margin-top: 4%;
}

.confirmationPage-btn button {
    height: 50px;
    color: #fff;
    border: none;
    font-size: 20px
}

.confirmationPage-btn-confirm {
    background-color: #004387;
    width: 48%;
}

.confirmationPage-btn-cancel {
    background-color: red;
    width: 590%
}

.confirmationModal-container {
    background-color: #fff;
    padding-top: 10%;
    height: 100vh;
    margin-top: -70px;
}

.confirmationModal-button {
    height: 50px;
    width: 50px;
    font-size: 1.5em;
    position: relative;
    left: 90%;
    border: none;
    cursor: pointer;
    font-family: 'Avenir Medium'
}

.confirmationModal-part2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
    justify-content: center;
    border-top: 1px solid #252954;
    padding-top: 2%;
}

.confirmationModal-part2-text {
    margin-bottom: 2%;
    font-size: 20px;
    font-family: 'Avenir Medium'
}

.confirmationModal-part2-form {
    display: flex;
    flex-direction: column;
    width: 63%;
    justify-content: center;
    align-items: center
}

.confirmationModal-part2-textArea {
    height: 100px;
    width: 100%;
    border-color: #004387;
    margin-bottom: 2%;
    font-family: 'Avenir Medium'
}

.confirmationModal-photo-container {
    width:20%;
    margin-bottom:2%;
    margin-left: 40%
}

.confirmationModal-photo {
    height: 232px;
    margin-top: 6%;
    width: 22%;
    margin-bottom: 5%;
    background-size: cover;
    background-repeat: no-repeat;
}

.confirmationModal-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.successImage {
    width: 100%;
    object-fit: cover;
}

.confirmationModal-message-success {
    color: #fd4b95;
    font-size: 16px;
    margin-bottom: 3%;
    margin-top: 3%;
    font-family: 'Avenir Black';
}

.confirmationModal-message-parts {
    width: 80%;
    font-family: 'Avenir Medium'
}

.confirmationModal-message-part4 {
    margin-top: 3%;
    font-size: 17px;
    font-family: 'Avenir Heavy'
}

.confirmationModal-submit {
    background-color: #fd4b95 !important;
    box-shadow: none !important;
    width: 32%;
    font-weight: 500 !important;
    font-size: 15px;
    font-family: 'Avenir Heavy'
}

.modal-dialog .modal-content .modal-header {
    border: none !important
}

.modal-footer {
    border: none !important
}

.content {
    min-height: 100vh;
    padding-top: 70px;
    background-color: #f2f2f2;
}

@media screen and (min-device-width: 1600px) {
    .MerchantPage {
        background-color: rgb(242, 242, 242);
    }
    .productItem-price {
        min-width: 15%;
        margin-left: -1%;
    }
    .confirmationModal-photo-container {
    margin-left: 43%;  
    }
}

@media screen and (max-device-width: 1200px) {
    .productItem-price {
        min-width: 19%;
        margin-left: -5%;
    }
    .subcategoryProduct {
        width: 38%;
    }
    .merchantBody-collapseSubcategory-items> :nth-child(2n+1) {
        margin-right: 23%
    }
    .confirmationPage-btn {
        height: auto;
        margin-top: 10%;
    }
}
.ForgetPasswordPage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ForgetPasswordPage-header {
    text-align: center
}

.ForgetPasswordPage-header img {
    width: 50%;
    margin-top: 22%;
}

.ForgetPasswordPage-text {
    color: #ffffff !important;
    font-family: 'Avenir Heavy';
    margin-bottom:5%;
    margin-top:2%
}

.ForgetPasswordPageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ForgetPasswordPageContainer input {
    background-color: #ffffff !important;
    border-radius: 50px;
    padding: 0px 30px;
    border-color: transparent;
    color: #6f6f6f !important;
    font-size: 16px;
    font-family: 'Avenir Medium';
    line-height: 20px;
    box-shadow: none;
    height: 48px !important;
    margin-bottom: 8px;
    width: 136%;
    margin-left: -19%;
}

.ForgetPasswordPageContainer button {
    color: white !important;
    font-family: 'Avenir Medium';
    border-radius: 21px;
    font-size: 1rem;
    text-transform: none;
    text-transform: initial;
    height: 3rem;
    border: none;
    background-color: #004387 !important;
    width: 136%;
    margin-top: 14%;
    margin-right: 3%
}
@media screen and (min-device-width:1600px){
    .ForgetPasswordPageContainer input {
        width: 136%;
        margin-left: -19%;
    }
    
    .ForgetPasswordPage-header img {
        width: 50%;
        margin-top: 45%;
    }

}
