/* @font-face {
    font-family: 'Avenir Black Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Black Oblique'), url(../../assets/fonts/avenir_black_oblique.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Black';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Black'), url(../../assets/fonts/avenir_black.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Book Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Book Oblique'), url(../../assets/fonts/avenir_book_oblique.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Book';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Book'), url(../../assets/fonts/avenir_Book.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Heavy Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Heavy Oblique'), url(../../assets/fonts/avenir_heavy_oblique.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Heavy'), url(../../assets/fonts/avenir_heavy.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Light Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Light Oblique'), url(../../assets/fonts/avenir_light_oblique.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Light';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Light'), url(../../assets/fonts/avenir_light.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Medium Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Medium Oblique'), url(../../assets/fonts/Avenir_medium_oblique.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Medium'), url(../../assets/fonts/avenir_medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Oblique'), url(../../assets/fonts/avenir_oblique.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Roman';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Roman'), url(../../assets/fonts/avenir_roman.ttf) format('truetype');
} */

.HomeHeader {
    text-align: center;
    font-size: 18px;
    margin-bottom: 8%;
    font-family: 'Avenir Medium'
}

.HomeHeader-list-container {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

.cardRestau {
    position: relative;
    box-shadow: none;
    margin: 0 1.5% 25px 1.5%;
    width: 30%;
}

.cardRestau .cardRestauBody {
    padding-bottom: 46px;
}

.cardDate {
    width: 80%;
    margin-left: 10%;
    margin-top: 2%;
    display: flex;
    justify-content: space-between;
    height: 48px;
    color: rgb(0, 174, 244);
    font-family: 'Avenir Medium';
    padding-top: 3%
}

.card-text {
    color: #333333;
    font-family: 'Avenir Medium';
}

.HomeHeader span {
    position: relative;
    top: 44px;
}

.MerchantPage {
    background-color: rgb(242, 242, 242);
    margin-left: 4%;
}

.MerchantPage-btn-back {
    margin-top: 3%;
    margin-bottom: -2%;
    color: #fd4b95;
    font-size: 24px;
}

.photoMerchant {
    height: 200px;
    min-height: 200px;
    max-height: 200px;
    position: relative;
}
.logoMerchant-container{
    position: absolute;
    left:27.5%;
    height: 100px;
    top:100px;
    width: 45%;
   
    /* margin-left: 25%; */
}
.logoMerchant {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.remember {
    height: 73px;
    width: 73px;
    position: absolute;
    top: 12%;
    left: 1%;
}

.form-check-label {
    font-family: 'Avenir Medium'
}

.cardRestau-btn {
    background-color: #fd4b95;
    color: #fff !important;
    position: absolute;
    bottom: 0px;
    left: 0;
    margin-top: 2%;
    font-family: 'Avenir Heavy';
    box-shadow: none !important
}

.cardRestauLink-container a {
    color: #fff !important
}

.cardRestauLink-container a:hover {
    color: #fff !important
}

/**Merchant Header**/

.card-title {
    color: #004387;
    font-family: 'Avenir Heavy'
}

.cardRestau-radio {
    margin-top: 5%;
    color: #004387;
    padding-left: 0 !important
}

.merchantParagraph {
    background-color: #ffffff;
    color: #333333;
    height: 236px;
    padding-bottom: 4%;
    font-family: 'Avenir Medium'
}

.merchantHeader {
    top: 40px;
}

.MerchantPage {
    padding-left: 70px !important;
}

.merchantName {
    margin-top: 4%;
    color: #004387;
    font-weight: 500;
    margin-bottom: 3%;
    font-family: 'Avenir Heavy'
}

.merchantDescription {
    max-height: 64%;
    overflow-y: auto;
    text-overflow: ellipsis;
}

.DeliveryTime {
    margin-top: 2% !important;
}

.merchantMeal {
    color: #fd4b95
}

.userCart {
    background-color: #b3b3b3;
    left: 3% !important;
    padding-top: 3%;
    text-align: center;
    font-family: 'Avenir Heavy'
}

.userCart a, .userCartPink a {
    color: #ffffff
}

.userCartPink {
    padding-top: 3%;
    text-align: center;
    background-color: #fd4b95;
    left: 3% !important;
    font-weight: 400;
}

.merchantPhoto {
    opacity: 0.7;
    background-position: center center
}
.merchantLogo-container{
    height: 100px;
    width: 15%;
    position: absolute;
    top: 25%;
    right:80%
}
.merchantLogo{
    object-fit: contain;
    height: 100%;
    width: 100%;
    
}
/*** merchant body***/

.merchantBody {
    background-color: #ffffff;
    margin-top: 8%;
    padding-bottom: 2%;
    width: 94.8%;
    margin-left: 0% !important;
}

.merchantBody-container {
    width: 96%;
    padding-top: 1%;
    margin-left: 2%;
}

.merchantBody-title {
    color: #004387;
    margin-top: 2%;
    font-size: 20px;
    margin-bottom: 1%;
    font-family: 'Avenir Heavy'
}

.fa-angle-down:before {
    content: "\f107";
    box-sizing: border-box;
    position: relative;
    top: 39%;
}

.fa-angle-down.rotate-icon {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 150ms ease-in 0s;
    -o-transition: all 150ms ease-in 0s;
    transition: all 150ms ease-in 0s;
}

.showed {
    color: #00aef4 !important;
}

.merchantBody-collapse-btn {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
    cursor: pointer;
    font-family: 'Avenir Heavy'
}

.merchantBody-collapse-btn button {
    background-color: #ffffff;
    border: none;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: none;
}

.custom-radio .custom-control-input:checked~.custom-control-label {
    color: #26b4f5
}

.custom-checkbox .custom-control-input:checked~.custom-control-label {
    color: #26b4f5;
}

.custom-control-label::after {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    border-radius: 100%;
    background-color: #007bff;
}

.custom-control-input~.custom-control-label::before {
    border-radius: 100%;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    color: #007bff;
    border-color: #007bff;
    border-radius: 100% 100% 100%;
    background-color: #007bff;
    background-image: none;
}

.merchantBody-collapse {
    padding: 0 1% 0 2%;
    transition: none
}

.productItem {
    margin-right: 2%;
    font-family: 'Avenir Medium'
}

.productItem-title {
    font-size: 15px;
    font-family: 'Avenir Medium'
}

.productItem-price {
    font-size: 15px;
    font-family: 'Avenir Medium';
    min-width: 16%;
    margin-left: 0%;
    text-align: right;
}


.colProduct {
    padding: 0px !important;
    margin-left: -0.6%;
    margin-right: 0.5%;
    font-weight: 400;
}

.MerchantMenu-btn {
    width: 100%;
    color: #fff;
    background-color: #fd4b95;
    border: none;
    height: 45px;
    margin-top: 3%;
    font-family: 'Avenir Heavy'
}

.iconSubcategoryProduct {
    position: relative;
    left: -0.5%;
}

.subCategory-collapse {
    width: 254.5%;
    margin-left: -0.7%;
}

.merchantBody-collapse-category {
    margin-bottom: 1%;
    margin-top: 1%;
    color: #5d5d5d;
    font-weight: 500;
    margin-left: 0.3%;
}

.merchantBody-collapse-Subcategory {
    margin-left: 1.3%;
    margin-bottom: 1%;
    color: #5d5d5d;
    font-weight: 500;
    font-size: 15px
}

.rowProduct {
    display: flex;
    width: 102%;
    margin-top: 1%;
    flex-wrap: wrap;
}

.rowProduct> :nth-child(2n+1) {
    margin-right: 22%;
    margin-left: -2%;
}

.rowProduct> :nth-child(2n) {
    width: 40%
}

.colProduct {
    width: 40%;
    margin-bottom: 1%;
}

.merchantBody-collapseSubcategory {
    width: 102%;
    margin-left: -1.4%
}

.merchantBody-collapseSubcategory-items {
    display: flex;
    flex-wrap: wrap;
}

.merchantBody-collapseSubcategory-items> :nth-child(2n+1) {
    margin-right: 22.9%;
}

.subcategoryProduct {
    width: 38%;
}

.productItem-subcategory-title {
    min-width: 59%;
}

.productItem-subcategory-label {
    width: 102%;
    margin-bottom: 2%;
    margin-left: -0.75%;
}

/****Merchant Product Page****/

.merchantProductPage {
    min-height: 92.2vh;
    padding-left: 70px !important;
}

.merchantProductBody {
    background-color: #ffffff;
    margin-top: 8%;
    padding-bottom: 2%;
    width: 94.8%;
    margin-left: 0% !important;
}

.merchantProductBody-container {
    width: 96%;
    padding-top: 2%;
    margin-left: 2%;
}

.merchantProductBody-title {
    color: #004387;
    font-size: 20px;
    font-family: 'Avenir Heavy'
}

.merchantProduct-quantity {
    color: #01aff5;
    font-weight: 500;
    font-size: 20px;
    position: relative;
    bottom: 15%;
}

.merchantProductBody-description {
    color: #333333;
    font-family: 'Avenir Medium'
}

.merchantProductBody-modifiers {
    color: #00aef4;
    font-size: 18px;
    font-family: 'Avenir Heavy'
}

.merchantProduct-btn {
    margin-top: 5%;
    font-family: 'Avenir Heavy'
}

.merchantProduct-btn button {
    width: 100%;
    color: #fff;
    border: none;
    background-color: #fd4b95;
    height: 45px
}

.merchantProduct-btn-cancel {
    background-color: #b3b3b3 !important
}

/*****Confirmation Page***/

.confirmationPage-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: -70px;
}

.confirmationPage-counter {
    color: #fff;
    font-size: 50px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    margin-bottom: 8%;
    font-family: 'Avenir Heavy'
}

.confirmationPage-p {
    color: #fff;
    font-size: 20px;
    width: 80%;
    text-align: center;
    margin-left: 10%;
    font-family: 'Avenir Medium'
}

.confirmationPage-btn {
    height: 60%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-family: 'Avenir Heavy';
    margin-top: 4%;
}

.confirmationPage-btn button {
    height: 50px;
    color: #fff;
    border: none;
    font-size: 20px
}

.confirmationPage-btn-confirm {
    background-color: #004387;
    width: 48%;
}

.confirmationPage-btn-cancel {
    background-color: red;
    width: 590%
}

.confirmationModal-container {
    background-color: #fff;
    padding-top: 10%;
    height: 100vh;
    margin-top: -70px;
}

.confirmationModal-button {
    height: 50px;
    width: 50px;
    font-size: 1.5em;
    position: relative;
    left: 90%;
    border: none;
    cursor: pointer;
    font-family: 'Avenir Medium'
}

.confirmationModal-part2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
    justify-content: center;
    border-top: 1px solid #252954;
    padding-top: 2%;
}

.confirmationModal-part2-text {
    margin-bottom: 2%;
    font-size: 20px;
    font-family: 'Avenir Medium'
}

.confirmationModal-part2-form {
    display: flex;
    flex-direction: column;
    width: 63%;
    justify-content: center;
    align-items: center
}

.confirmationModal-part2-textArea {
    height: 100px;
    width: 100%;
    border-color: #004387;
    margin-bottom: 2%;
    font-family: 'Avenir Medium'
}

.confirmationModal-photo-container {
    width:20%;
    margin-bottom:2%;
    margin-left: 40%
}

.confirmationModal-photo {
    height: 232px;
    margin-top: 6%;
    width: 22%;
    margin-bottom: 5%;
    background-size: cover;
    background-repeat: no-repeat;
}

.confirmationModal-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.successImage {
    width: 100%;
    object-fit: cover;
}

.confirmationModal-message-success {
    color: #fd4b95;
    font-size: 16px;
    margin-bottom: 3%;
    margin-top: 3%;
    font-family: 'Avenir Black';
}

.confirmationModal-message-parts {
    width: 80%;
    font-family: 'Avenir Medium'
}

.confirmationModal-message-part4 {
    margin-top: 3%;
    font-size: 17px;
    font-family: 'Avenir Heavy'
}

.confirmationModal-submit {
    background-color: #fd4b95 !important;
    box-shadow: none !important;
    width: 32%;
    font-weight: 500 !important;
    font-size: 15px;
    font-family: 'Avenir Heavy'
}

.modal-dialog .modal-content .modal-header {
    border: none !important
}

.modal-footer {
    border: none !important
}

.content {
    min-height: 100vh;
    padding-top: 70px;
    background-color: #f2f2f2;
}

@media screen and (min-device-width: 1600px) {
    .MerchantPage {
        background-color: rgb(242, 242, 242);
    }
    .productItem-price {
        min-width: 15%;
        margin-left: -1%;
    }
    .confirmationModal-photo-container {
    margin-left: 43%;  
    }
}

@media screen and (max-device-width: 1200px) {
    .productItem-price {
        min-width: 19%;
        margin-left: -5%;
    }
    .subcategoryProduct {
        width: 38%;
    }
    .merchantBody-collapseSubcategory-items> :nth-child(2n+1) {
        margin-right: 23%
    }
    .confirmationPage-btn {
        height: auto;
        margin-top: 10%;
    }
}