.ForgetPasswordPage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ForgetPasswordPage-header {
    text-align: center
}

.ForgetPasswordPage-header img {
    width: 50%;
    margin-top: 22%;
}

.ForgetPasswordPage-text {
    color: #ffffff !important;
    font-family: 'Avenir Heavy';
    margin-bottom:5%;
    margin-top:2%
}

.ForgetPasswordPageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ForgetPasswordPageContainer input {
    background-color: #ffffff !important;
    border-radius: 50px;
    padding: 0px 30px;
    border-color: transparent;
    color: #6f6f6f !important;
    font-size: 16px;
    font-family: 'Avenir Medium';
    line-height: 20px;
    box-shadow: none;
    height: 48px !important;
    margin-bottom: 8px;
    width: 136%;
    margin-left: -19%;
}

.ForgetPasswordPageContainer button {
    color: white !important;
    font-family: 'Avenir Medium';
    border-radius: 21px;
    font-size: 1rem;
    text-transform: none;
    text-transform: initial;
    height: 3rem;
    border: none;
    background-color: #004387 !important;
    width: 136%;
    margin-top: 14%;
    margin-right: 3%
}
@media screen and (min-device-width:1600px){
    .ForgetPasswordPageContainer input {
        width: 136%;
        margin-left: -19%;
    }
    
    .ForgetPasswordPage-header img {
        width: 50%;
        margin-top: 45%;
    }

}