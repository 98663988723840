@font-face {
    font-family: 'Avenir Black Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Black Oblique'), url(./assets/fonts/avenir_black_oblique.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Black';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Black'), url(./assets/fonts/avenir_black.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Book Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Book Oblique'), url(./assets/fonts/avenir_book_oblique.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Book';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Book'), url(./assets/fonts/avenir_Book.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Heavy Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Heavy Oblique'), url(./assets/fonts/avenir_heavy_oblique.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Heavy'), url(./assets/fonts/avenir_heavy.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Light Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Light Oblique'), url(./assets/fonts/avenir_light_oblique.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Light';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Light'), url(./assets/fonts/avenir_light.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Medium Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Medium Oblique'), url(./assets/fonts/Avenir_medium_oblique.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Medium'), url(./assets/fonts/avenir_medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Oblique'), url(./assets/fonts/avenir_oblique.ttf) format('truetype');
}

@font-face {
    font-family: 'Avenir Roman';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Roman'), url(./assets/fonts/avenir_roman.ttf) format('truetype');
}

.loginPage {
    width: 100vw;
    min-height: 100vh;
    background-size: cover
}

.loginPage-container {
    text-align: center;
    width: 50%;
    margin-left: 25%;
    font-family: 'Avenir Heavy'
}

.loginPage-header img {
    width: 34%;
    margin-top: 10%;
    margin-right: 11%;
}

.loginPage-text p {
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 8%;
}

.loginPage-form {
    text-align: center;
    font-family: 'Avenir Medium'
}

.loginPage input {
    background-color: #ffffff !important;
    border-radius: 50px;
    padding: 0px 30px;
    border-color: transparent;
    color: #6f6f6f !important;
    font-size: 16px;
    font-family: 'Avenir Medium';
    line-height: 20px;
    box-shadow: none;
    height: 48px !important;
    margin-bottom: 8px;
    width: 50%;
}

.loginPage button {
    color: white !important;
    font-family: 'Avenir Medium';
    border-radius: 21px;
    font-size: 1rem;
    text-transform: none;
    text-transform: initial;
    height: 3rem;
    border: none;
    width: 50%
}

.loginPage-header {
    margin-left: 11% !important;
}

.loginPage-box:focus {
    border: 1px solid #ff4694 !important;
    box-shadow: none !important
}

.loginPage-signIn {
    color: white;
    background-color: #ff4694 !important;
}

.loginPage-signIn:hover {
    background-color: #363287 !important;
    border-color: #363287 !important;
}

.loginPage-link {
    margin-bottom: 23px;
    color: #fff !important;
    font-size: 16px;
    line-height: 18px;
    margin-top: 22px !important;
}

.loginPage-link a {
    color: #fff
}

.loginPage-link a:hover {
    color: #3c8dbc
}

.googleIcon {
    color: #fff;
    width: 23px;
    height: 23px;
    margin-bottom: 1px;
    position: relative;
    right: 4px;
    bottom: 1px;
}

.loginPage-google {
    background-color: #00aeef !important;
}

.LoginError {
    font-size: 14px;
    color: #fff;
}

.GoogleLoginError {
    margin-top: 4%
}

/***register Choice***/

.registerChoice {
   min-height: 100vh;
    width: auto !important;
    font-family: 'Avenir Medium'
}

.registerChoice-container {
    width: 50%;
    margin-left: 25%;
    text-align: center
}

.registerChoice-header img {
    width: 30%;
    margin-top: 15%;
}

.registerChoice-text {
    font-size: 20px;
    font-family: 'Avenir Heavy';
    line-height: 30px;
    color: #fff;
    font-weight: 400;
    margin-top: 10px;
}

.registerChoice-btn {
    color: #fff !important;
    border-radius: 50px !important;
    font-size: 16px !important;
    height: 48px !important;
    border: none;
    width: 50%;
    margin-bottom: 2%
}

.registerChoice-btn-email {
    background-color: #ff4694 !important;
}

.registerChoice-btn-google {
    margin-top: 9%;
    background-color: #00aeef !important;
}

.emailIcon {
    color: #fff;
    width: 23px;
    height: 23px;
    position: relative;
    right: 13px;
}

.registerChoice-policy {
    color: #fff;
    position: fixed;
    bottom: 10px;
}

.registerChoice-policy a {
    color: #363287;
    text-decoration: underline
}

/****register page****/

.registerPage {
    min-height: 100vh;
    width: 100vw;
    background-size: cover;
    font-family: 'Avenir Medium'
}

.registerPage-header {
    text-align: center
}

.registerPage-header img {
    width: 16%;
    margin-top: 6%;
}

.registerPage-text {
    text-align: center;
    font-family: 'Avenir Heavy'
}

.registerPage-text p {
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 3%;
}

.registerStep {
    text-align: center;
}

/**step1*/

.registerstep1 {
    text-align: center
}

.registerstep1 input {
    background-color: #ffffff !important;
    border-radius: 50px;
    padding: 0px 30px;
    border-color: transparent;
    color: #6f6f6f !important;
    font-size: 16px;
    line-height: 20px;
    box-shadow: none;
    height: 48px !important;
    width: 20%;
    font-family: 'Avenir Medium';
    margin: auto
}

.registerStep1-btn {
    width: 20%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-top: 3%;
}

.registerStep1-btn button {
    background-color: #ff4694 !important;
    color: white !important;
    border-radius: 21px;
    font-size: 1rem;
    text-transform: initial;
    height: 3rem;
    border: none;
    width: 45%
}

/**step2**/

.registerStep2 {
    text-align: center;
}

.registerStep2 input {
    background-color: #ffffff !important;
    border-radius: 50px;
    padding: 0px 30px;
    border-color: transparent;
    color: #6f6f6f !important;
    font-size: 16px;
    line-height: 20px;
    box-shadow: none;
    height: 48px !important;
    width: 20%;
    margin: auto
}

.registerStep2 select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius: 50px;
    background-color: #ffffff !important;
    border-radius: 50px;
    padding: 0px 30px;
    border-color: transparent;
    color: #6f6f6f !important;
    font-size: 16px;
    line-height: 20px;
    box-shadow: none;
    height: 48px !important;
    margin: auto;
    width: 20%;
    border:0px;
    outline:0px;
}

.registerStep2-btn {
    width: 20%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-top: 3%;
}

.registerStep2-btn button {
    background-color: #ff4694 !important;
    color: white !important;
    border-radius: 21px;
    font-size: 1rem;
    text-transform: none;
    text-transform: initial;
    height: 3rem;
    border: none;
    width: 45%;
}

/**step3*/

.registerstep3 {
    text-align: center
}

.registerstep3 button {
    background-color: #ff4694 !important;
    color: white !important;
    border-radius: 21px;
    font-size: 1rem;
    text-transform: none;
    text-transform: initial;
    width: 20%;
    height: 3rem;
    border: none;
}

.registerStep3-text {
    width: 100%;
    color: #fff;
}

.registerStep3-btn {
    width: 100%;
    margin-top: 7%;
}

.registerStep3-text-p1 {
    font-weight: 500;
}

.registerStep3-text-p3 {
    margin-bottom: 3%
}

.registerStep3-input {
    width: 20%;
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
    margin: auto
}

.registerstep3 input {
    background-color: transparent !important;
    /* border-bottom: 4px solid #ffff; */
    text-align: center;
    border-bottom-color:white;
    border-bottom-width: 3px;
    border-top: none;
    border-left: none;
    border-right: none;
    color: #fff !important;
    font-size: 30px;
    font-weight: 400;
    line-height: 20px;
    box-shadow: none;
    height: 48px !important;
    width: 16%;
    -webkit-appearance: none;
    border-radius: 0;
    font-family: 'Avenir Medium'
}

.registerError-step3 {
    font-size: 14px;
    color: #fff !important;
    margin-top: 1%;
}

.registerstep3 input:focus {
    box-shadow: none !important;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-color:white;
    border-bottom-width: 3px;
}

/**step 4*/

.registerStep4 {
    text-align: center;
}

.registerStep4 input {
    background-color: #ffffff !important;
    border-radius: 50px;
    padding: 0px 30px;
    border-color: transparent;
    color: #6f6f6f !important;
    font-size: 16px;
    line-height: 20px;
    box-shadow: none;
    height: 48px !important;
    width: 20%;
    font-family: 'Avenir Medium';
    margin: auto;
}

.registerStep4 button {
    background-color: #ff4694 !important;
    color: white !important;
    border-radius: 21px;
    font-size: 1rem;
    text-transform: none;
    text-transform: initial;
    width: 20%;
    height: 3rem;
    border: none;
}

.registerStep4-btn {
    margin-top: 5%
}

.selectIcon {
    position: absolute;
    top: 30%;
    left: 44%;
}
.registerStep5{
    margin-top:-2%
}
.registerstep1 input:focus, .registerStep2 input:focus, .registerStep4 input:focus, .registerStep5 input:focus, .registerPage select:focus {
    border: 1px solid #ff4694 !important;
    box-shadow: none !important
}

.registerStep button {
    background-color: #ff4694 !important;
    color: white !important;
    border-radius: 21px;
    font-size: 1rem;
    text-transform: none;
    text-transform: initial;
    width: 50%;
    height: 3rem;
    border: none;
}

.registerStep button:hover {
    background-color: #363287 !important;
    border-color: #363287 !important;
}

.registerIcon {
    margin-top: 29%
}

/**step5**/

.registerStep5 {
    text-align: center;
}

.registerStep5 input {
    background-color: #ffffff !important;
    border-radius: 50px;
    padding: 0px 30px;
    border-color: transparent;
    color: #6f6f6f !important;
    font-size: 16px;
    line-height: 20px;
    box-shadow: none;
    height: 48px !important;
    width: 50%;
}

.registerStep5-text {
    color: #fff;
    font-size: 20px;
}

.registerStep5-uploadButton {
    margin-top: 3%
}

#registerFileInput {
    background: #363287;
    display: table;
    color: #fff;
    border-radius: 21px;
    font-size: 1rem;
    text-transform: none;
    text-transform: initial;
    width: 20%;
    height: 3rem;
    border: none;
    padding: 10px;
    margin: auto;
}

.registerStep5-cam {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: -2%;
}

.registerStep5-cam button {
    background-color: #ff4694 !important;
    color: white !important;
    border-radius: 21px;
    font-size: 1rem;
    text-transform: none;
    text-transform: initial;
    height: 3rem;
    border: none;
    margin: auto;
    width: 20%;
}

.registerCamera {
    margin-top: 35%;
}

.registerStep5-btn {
    display: flex;
    justify-content: space-between;
    width: 20%;
    margin: auto;
    padding-top: 1%;
}

.registerStep5-btn button {
    background-color: #ff4694 !important;
    color: white !important;
    border-radius: 21px;
    font-size: 1rem;
    text-transform: initial;
    height: 3rem;
    border: none;
    height: 43px;
    width: 45%
}

.registerPictureZone {
    height: 200px;
    width: 200px;
    border: 1px solid #fff;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    margin: auto;
}

input[type="file"] {
    display: none;
}

.registerError {
    font-size: 14px;
    color: #fff !important;
}

.registerSkip {
    font-weight: 400;
    font-size: 20px;
    top: 25%;
    left: 85%;
    position: absolute;
}

.registerSkip a {
    color: #fff !important;
}

#modalCloseButton {
    border-radius: 0% !important
}

#modalCloseButton {
    border-radius: 0% !important
}

/*Navigation bar Menu*/

.nav-container {
    background-color: rgb(7, 69, 134);
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.nav-container .user-details {
    display: flex;
    align-items: center;
}

.user-details div {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 50px;
    width: 50px;
    border-radius: 100%;
}

.menu span:first-child {
    display: flex;
    align-items: center;
}

.back-arrow-container {
    padding: 0% 3% 3%
}

.back-arrow-style {
    color: #fd4b95;
    font-size: 24px;
}

.menu .active span {
    color: #1daff1;
}

/*Sidebar Menu*/

#sidebar {
   min-height: 100vh;
    background-color: #36343e;
    width: 20%;
    position: fixed;
}

#right-container {
    width: 80%;
    margin-left: 20%;
    position: relative;
}

#sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 20px;
    color: #ffffff;
    display: block;
}

#sidebar .menu {
    padding-top: 100px;
}

.menuSpan {
    width: 9%;
    display: flex;
    justify-content: center;
}

.modal-visible {
    display: block;
    padding-right: 15px;
    background: rgba(108, 108, 108, 0.6);
}

.modal-hidden {
    display: none;
}

.flavour-form {
    padding: 1% 2%;
    background-color: #ffffff;
    margin: 0 3%;
}

.flavorRadios {
    display: flex
}
.flavorRadios input {
    margin-left:5%
}

.sidebar-logo {
    text-align: center;
    padding-top: 30px;
}

.pink-button {
    background-color: #fd4b95;
    padding: 8px 20px;
    color: #ffffff;
    width: 48%;
    font-family: 'Avenir Heavy';
    border: none !important;
}

.cash-label {
    border: 1px solid #fd4b95;
    padding: 10px 20px;
    font-family: 'Avenir Medium';
    width: 100%;
    text-align: center;
}

.cash-label-selected {
    border: 1px solid #fd4b95;
    padding: 10px 20px;
    background-color: #fd4b95;
    color: #ffffff;
    width: 100%;
    text-align: center;
    font-family: 'Avenir Heavy'
}

.blue-button {
    background-color: #064485;
    padding: 8px 20px;
    color: #ffffff;
    width: 48%;
    font-family: 'Avenir Heavy';
    border: none !important
}

.light-blue-button {
    background-color: #1db0f2;
    padding: 8px 20px;
    color: #ffffff;
    width: 28%;
    font-family: 'Avenir Heavy';
    border: none !important
}

.light-blue-text {
    color: #1eb0f2;
}

.delete-order-link:link, .delete-order-link:visited {
    color: #b3b3b3;
    font-weight: bold;
}

.delete-order-link:hover, .delete-order-link:active {
    font-weight: bold;
}

.grey-button {
    background-color: #b3b3b3;
    padding: 8px 20px;
    color: #ffffff;
    width: 48%;
    font-family: 'Avenir Heavy';
    border: none !important;
}

.rounded-profile-image {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    background-size: cover;
    background-position: center;
}

.rounded-profile-image span {
    font-family: 'Avenir Medium'
}

.rounded-profile-image-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: rgba(108, 108, 108, 0.4);
    color: #ffffff;
}

.order-item-container {
    background-color: #ffffff;
    padding: 20px;
    margin-top: 30px;
}

.orderNote {
    font-family: 'Avenir Medium'
}

.cash-style {
    color: #fc4a94;
    font-family: 'Avenir Heavy'
}

.cash-title {
    color: #4c4c4c;
    font-weight: bold;
}

.order-text-blue {
    color: #074585;
    font-family: 'Avenir Heavy';
    margin-top: 1%
}

.order-container {
    font-family: 'Avenir Medium'
}

.flavour-title {
    color: #1daff1;
    font-family: 'Avenir Heavy'
}

.flavour-form .form-check {
    margin-right: 5rem;
}

.content-spaced-between {
    display: flex;
    justify-content: space-between;
    font-family: 'Avenir Medium'
}

.description-container {
    border-bottom: 1px solid #f2f2f2;
    margin-top: 20px;
    color: #1eaff1;
    font-family: 'Avenir Heavy'
}

.from-container {
    padding: 5%;
    background-color: #ffffff;
    position: relative;
    top: 8%;
    margin: 0 3%;
}

.MessageModal-btn button {
    background-color: #074585 !important;
    border-radius: 0% !important
}

.MessageModalClose-btn button {
    border-radius: 0% !important;
    background-color: #fff !important;
    color: #000 !important;
    width: 9%;
    font-size: 18px;
}

.settings-form select, .settings-form input {
    font-family: 'Avenir Medium' !important
}

.settingsContainer .react-datepicker-manager, .settingsContainer .react-datepicker-wrapper, .settingsContainer .react-datepicker__input-container {
    width: 100%;
}

.paymentForm label {
    text-align: left;
    font-family: 'Avenir Medium'
}

.list-unstyled {
    font-family: 'Avenir Medium'
}
#canvas{
    display: none !important;
}
@media screen and (max-device-width: 1280px) {
    .registerstep3 input {
        font-size: 18px;
        width: 15%;
    }
    /* .registerStep5{
        margin-top:-2%
    } */
}

@media screen and (min-device-width: 1600px) {
    /**login*/
    .loginPage-container {
        text-align: center;
        width: 40%;
        margin-left: 30%;
    }
    .loginPage-header img {
        width: 34%;
        margin-top: 21%;
        margin-right: 11%;
    }
    .loginPage-text p {
        font-size: 20px;
        line-height: 30px;
        color: #fff;
        font-weight: 400;
        margin-top: 10px;
        margin-bottom: 14%;
        margin-right: 0%;
    }
    .loginPage input {
        font-size: 16px;
        width: 50%;
    }
    .loginPage button {
        width: 50%;
    }
    /**rgister**/
    .registerChoice-btn {
        width: 40%
    }
    .registerChoice-policy {
        margin-left: 8%
    }
    .registerChoice-header img {
        width: 24%;
        margin-top: 21%;
    }
    .registerPage-header img {
        width: 12%;
        margin-top: 10%;
    }
    .selectIcon {
        position: absolute;
        top: 30%;
        left: 37%;
    }
    .registerStep5-cam button,  #registerFileInput{
        width:17%
    }
}